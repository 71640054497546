export default defineStore('calculationStore', {
    state: () => ({
        headerTitle: '',
        headerPretitle: '',
        headerInfo: '',
        colorScheme: 'petrol',
        colorSchemeText: 'petrol',
        calculation: [],
        refrigeratorLandingPage: [],
        activeRefrigeratorIndex: 0,
        details: [],
        nextSteps: [],
        nextSteps2: [],
        breadcrumbs: [],
        requestBody: {},
        calculatedData: null,
        refrigerationSystem: {
            modul: '',
            type: '',
        },
        isCalculationSection: false,
        isDetailsSection: false,
        isNextStepsSection: false,
        isNextStepsDetailsSection: false,
        isRefrigeratorSection: false,
        colorInversion: false,
        urlApi: '',
        projectType: '',
        errorMessage: '',
    }),

    actions: {
        setHeaderTitle(title) {
            this.headerTitle = title;
        },

        setHeaderPretitle(pretitle) {
            this.headerPretitle = pretitle;
        },

        setHeaderInfo(info) {
            if (info === undefined || info === null || info === '') {
                this.headerInfo = '';
            } else {
                this.headerInfo = info;
            }
        },

        setColorScheme(colorScheme) {
            this.colorScheme = colorScheme;
        },

        setColorText(colorSchemeText) {
            this.colorSchemeText = colorSchemeText;
        },

        setCalculation(calculation, isRefrigerator) {
            if (!isRefrigerator) {
                this.calculation = calculation;
                this.headerTitle = this.calculation[0].title;
                this.headerPretitle = this.calculation[0].pretitle;
            } else {
                this.refrigeratorLandingPage = calculation;
                this.headerTitle = calculation[0].title;
                this.headerPretitle = calculation[0].pretitle;
            }
        },

        setCalculationDetails(details) {
            this.details = [];
            this.details = details;
        },

        setNextSteps(nextSteps) {
            this.nextSteps = nextSteps;
        },

        setNextSteps2(nextSteps2) {
            this.nextSteps2 = nextSteps2;
        },

        setBreadcrumbs(breadcrumbs) {
            this.breadcrumbs.push(breadcrumbs);
        },

        setRequestBody(data) {
            if (!this.requestBody.RentedOut) {
                this.requestBody.RentedOut = false;
            }

            if (data.value !== undefined && data.value !== null && data.value !== '' && !data.object) {
                // if data.name does exist anywhere in requestBody in an object, update it
                Object.keys(this.requestBody).forEach((key) => {
                    if (typeof this.requestBody[key] === 'object') {
                        Object.keys(this.requestBody[key]).forEach((key2) => {
                            if (data.name === key2) {
                                this.requestBody[key][key2] = data.value;
                            }
                        });
                    }
                });

                // add data.name to requestBody if it doesn't exist
                if (!this.requestBody[data.name]) {
                    this.requestBody[data.name] = data.value;
                }

                // if data.name already exists in requestBody, update it
                if (this.requestBody[data.name]) {
                    this.requestBody[data.name] = data.value;
                }

                // remove if undefined
                if (this.requestBody[data.name] === undefined) {
                    delete this.requestBody[data.name];
                }
            }

            /*
                Below here its all about the refrigeration system
            */
            // if data.object add it to requestBody and add data.value as an empty object
            if (data.object) {
                this.requestBody[data.name] = {};
                this.requestBody[data.name][data.value] = {};
            }

            if (data.name === 'project_type') {
                this.projectType = data.value;
            }

            // if data.parentObject find the key
            // which starts with data.parentObject and add data.value to it
            if (data.parentObject !== undefined
                && data.parentObject !== null
                && data.parentObject !== ''
                && this.requestBody.project_type !== undefined) {
                Object.keys(this.requestBody?.project_type).forEach((key) => {
                    if (key.startsWith(data.parentObject)) {
                        this.requestBody.project_type[key][data.name] = data.value;
                    }
                });
            }

            // if data.values make object of data.name and add each value.name
            if (data.values?.length > 0) {
                this.requestBody[data.name] = {};
                data.values.forEach((value) => {
                    this.requestBody[data.name][value.name] = value.value;
                });
            }

            // if there is a key application_year, write the actual year
            if (this.requestBody?.application_year) {
                this.requestBody.application_year = new Date().getFullYear();
            }

            if (this.requestBody?.plant?.commissioned) {
                this.requestBody.plant.commissioned = `${this.requestBody.commissioned}-01-01T00:00:00+02:00`;
            }

            // if there is a key "" and value false, delete it
            if (this.requestBody[''] === false) {
                delete this.requestBody[''];
            }
        },

        setCalculatedData(data) {
            this.calculatedData = data;
        },

        setRefrigerationSystem(data) {
            Object.keys(this.refrigerationSystem).forEach((key) => {
                if (data.name === key) {
                    this.refrigerationSystem[key] = data.value;
                }
            });
        },

        resetData() {
            this.colorScheme = 'petrol';
            this.colorSchemeText = 'petrol';
            this.calculation = [];
            this.details = [];
            this.nextSteps = [];
            this.breadcrumbs = [];
            this.calculatedData = null;
            this.urlApi = '';
            this.isCalculationSection = false;
            this.isDetailsSection = false;
            this.isNextStepsSection = false;
            this.isRefrigeratorSection = false;
            this.projectType = '';
            this.headerInfo = '';
            this.errorMessage = '';

            Object.keys(this.requestBody).forEach((key) => {
                delete this.requestBody[key];
            });

            this.refrigerationSystem = {
                modul: '',
                type: '',
            };
        },

        resetCaclData() {
            Object.keys(this.requestBody).forEach((key) => {
                delete this.requestBody[key];
            });

            this.calculatedData = null;
        },

        setColorInversion(colorInversion) {
            this.colorInversion = colorInversion;
        },

        setIsCalculationSection(isCalculationSection) {
            this.isCalculationSection = isCalculationSection;
        },

        setIsDetailsSection(isDetailsSection) {
            this.isDetailsSection = isDetailsSection;
        },

        setIsRefrigeratorSection(isRefrigeratorSection) {
            this.isRefrigeratorSection = isRefrigeratorSection;
        },

        setIsNextStepsSection(isNextStepsSection) {
            this.isNextStepsSection = isNextStepsSection;
        },

        setIsNextStepsSectionDetailsSection(isNextStepsDetailsSection) {
            this.isNextStepsDetailsSection = isNextStepsDetailsSection;
        },

        setUrlApi(urlApi) {
            this.urlApi = urlApi;
        },

        setErrorMessage(errorMessage) {
            this.errorMessage = errorMessage;
        },

        setActiveRefrigeratorIndex(index) {
            this.activeRefrigeratorIndex = index;
        },
    },

    getters: {
        getHeaderTitle() {
            return this.headerTitle;
        },

        getHeaderPretitle() {
            return this.headerPretitle;
        },

        getHeaderInfo() {
            return this.headerInfo;
        },

        getColorScheme() {
            return this.colorScheme;
        },

        getColorSchemeText() {
            return this.colorSchemeText;
        },

        getCalculation() {
            return this.calculation;
        },

        getDetails() {
            return this.details;
        },

        getNextSteps() {
            return this.nextSteps;
        },
        getNextSteps2() {
            return this.nextSteps2;
        },

        getBreadcrumbs() {
            return this.breadcrumbs;
        },

        getRequestBody() {
            return this.requestBody;
        },

        getCalculatedData() {
            return this.calculatedData;
        },

        getRefrigerationSystem() {
            return this.refrigerationSystem;
        },

        getColorInversion() {
            return this.colorInversion;
        },

        getIsCalculationSection() {
            return this.isCalculationSection;
        },

        getIsDetailsSection() {
            return this.isDetailsSection;
        },

        getIsRefrigeratorSection() {
            return this.isRefrigeratorSection;
        },

        getIsNextStepsSection() {
            return this.isNextStepsSection;
        },

        getIsNextStepsDetailsSection() {
            return this.isNextStepsDetailsSection;
        },

        getUrlApi() {
            return this.urlApi;
        },

        getProjectType() {
            return this.projectType;
        },

        getErrorMessage() {
            return this.errorMessage;
        },

        getRefrigeratorLandingPage() {
            return this.refrigeratorLandingPage;
        },

        getActiveRefrigeratorIndex() {
            return this.activeRefrigeratorIndex;
        },
    },
});
